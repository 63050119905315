import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ContactUsComponent} from './contact-us.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {RecaptchaModule} from "ng-recaptcha-2";
import {SafeHtmlPipeModule} from "../utils/pipes/safeHTMLPipe.module";
import {LoadingModule} from "../utils/loading/loading.module";
import {InputModule} from "../sharedComponents/input/input.module";

@NgModule({
  imports: [
    CommonModule, RouterModule,
    ReactiveFormsModule, MatAutocompleteModule, RecaptchaModule, SafeHtmlPipeModule, LoadingModule, InputModule],
  declarations: [
    ContactUsComponent
  ],
  providers: [],
  exports: [
    ContactUsComponent
  ]
})

export class ContactUsModule {
}
