import {HttpParams} from '@angular/common/http';
import {properties} from "../../../environments/environment";
import {Session} from "../login/utils/helper.class";
import {CommunityInfo} from "./community/communityInfo";

export class ConnectHelper {

  public static  getCommunityFromDomain(domain: string): string{
    if(properties.dashboard === 'irish') {
      return properties.adminToolsCommunity;
    }
    if(properties.environment == "development" &&
      (properties.adminToolsPortalType == "connect" || properties.adminToolsPortalType == "community"
        || properties.adminToolsPortalType == "aggregator" || properties.adminToolsPortalType == "eosc")) {
      // domain = "covid-19.openaire.eu"; //for testing
    }
    domain = domain.indexOf("//") != -1? domain.split("//")[1]:domain; //remove https:// prefix
    if (domain.indexOf('eosc-portal.eu') != -1 || domain.indexOf('eosc-beyond.eu') != -1) {
      return "eosc";
    }
    if (domain.indexOf('openaire.eu') === -1) {
      return null;
    }
    if ( domain.indexOf('beta') !== -1) {
      domain = domain.substr(domain.indexOf('.') + 1, domain.length);
      domain = domain.substr(0, domain.indexOf('.'));
    } else if (domain.indexOf('test.') !== -1 && !(properties.adminToolsPortalType == "connect" || properties.adminToolsPortalType == "community")) {
      return null;
    } else {
      domain = domain.substr(0, domain.indexOf('.'));
    }
    if (domain === 'connect' || domain === 'explore' || domain === 'monitor' || domain === 'admin' || domain === 'static'){
      return null;
    }
    return domain;
  }

  public static getCommunityFromPath(path: string): string {
    if (path.includes('?')) {
      const httpParams = new HttpParams({ fromString: path.split('?')[1] });
      return httpParams.get('communityId');
    } else {
      return null;
    }
  }

  public static setPortalTypeFromPid(pid: string): void {
    if(pid == "openaire") {
      properties.adminToolsPortalType = "explore";
    } else if(pid == "connect") {
      properties.adminToolsPortalType = "connect";
    } else if(pid == "monitor") {
      properties.adminToolsPortalType = "monitor";
    } else {
      properties.adminToolsPortalType = "community";
    }
  }


  public static isPrivate(community: CommunityInfo, user) {
    return community && (community.isPrivate() || (community.isRestricted() && !(
        Session.isPortalAdministrator(user) ||
        Session.isCommunityCurator(user) ||
        Session.isManager("community", community.communityId, user) ||
        (!community.isOpen() && Session.isMember('community', community.communityId, user))
    )))
  }
}
