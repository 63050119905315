import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

declare var UIkit: any;

@Component({
  selector: 'modal-alert',
  template: `
    <div #element class="uk-modal" [class.uk-modal-container]="large" [id]="id" uk-modal="container: #modal-container">
      <div class="uk-modal-dialog">
        <div class="uk-modal-header uk-flex uk-flex-middle uk-flex-between" [ngClass]="classTitle">
          <div [class.uk-invisible]="!alertHeader">
            <h6 class="uk-margin-remove">{{alertTitle}}</h6>
          </div>
          <button class="uk-close uk-icon uk-margin-left" (click)="cancel()">
            <icon name="close" ratio="1.4"></icon>
          </button>
        </div>
        <div #bodyElement class="uk-modal-body uk-animation-fast uk-text-left"
             [ngClass]="classBody" [attr.uk-overflow-auto]="overflowBody?'':null">
          <div *ngIf="message" [hidden]=!alertMessage [innerHTML]="message | safeHtml"></div>
          <ng-content></ng-content>
        </div>
        <div *ngIf="(choice || okButton || cancelButton) && alertFooter" class="uk-modal-footer">
          <div class="uk-grid uk-flex uk-flex-middle uk-margin-remove-left" uk-grid>
            <label *ngIf="choice" class="uk-width-expand">
              <input type="checkbox" [(ngModel)]="select">
              <span class="uk-margin-small-left">Don't show this message again</span>
            </label>
            <div [ngClass]="(choice)?'uk-width-auto':'uk-width-1-1 uk-padding-remove-horizontal'">
              <div class="uk-width-1-1" [ngClass]="(previousButton && (cancelButton || okButton)) ? 'uk-flex uk-flex-between uk-grid uk-margin-remove-left' : ''">
                <div *ngIf="previousButton" class="uk-flex-left uk-padding-remove-horizontal">
                  <button class="uk-button uk-button-default"
                          (click)="previous()">{{previousButtonText}}</button>
                </div>
                <div class="uk-flex-right uk-grid uk-grid-small" uk-grid>
                  <span *ngIf="okButton" [class.uk-flex-last]="!okButtonLeft">
                    <button class="uk-button uk-button-primary" [disabled]="okDisabled"
                            [class.uk-disabled]="okDisabled" (click)="ok()">{{okButtonText}}</button>
                  </span>
                  <span *ngIf="cancelButton">
                    <button class="uk-button uk-button-default uk-margin-small-left"
                            (click)="cancel()">{{cancelButtonText}}</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
/**
 * API to an open alert window.
 */
export class AlertModal implements OnInit, AfterViewInit, OnDestroy {
  private static MODAL_COUNTER: number = 0;

  id: string = "modal";
  @Input() classTitle: string = "uk-background-primary-opacity";
  @Input() classBody: string = "";
  @Input() large: boolean = false;
  @Input() overflowBody: boolean = true;
	@ViewChild("bodyElement") bodyElement: ElementRef;
  /**
   * Caption for the title.
   */
  public alertTitle: string;
  /**
   * Describes if the alert contains Ok Button.
   * The default Ok button will close the alert and emit the callback.
   * Defaults to true.
   */
  public okButton: boolean = true;
  /**
   * Caption for the OK button.
   * Default: Ok
   */
  public okButtonText: string = 'OK';
  /**
   * Describes if the alert contains cancel Button.
   * The default Cancelbutton will close the alert.
   * Defaults to true.
   */
  public cancelButton: boolean = true;
  /**
   * Caption for the Cancel button.
   * Default: Cancel
   */
  public cancelButtonText: string = 'Cancel';
  /**
   * if the alertMessage is true it will show the contentString inside alert body.
   */
  /**
   * Describes if the alert contains Previous Button.
   * The default Previous button will emit the callback.
   * Defaults to false.
   */
  public previousButton: boolean = false;
  /**
   * Caption for the Previous button.
   * Default: Previous
   */
  public previousButtonText: string = 'Previous';
  public alertMessage: boolean = true;
  /**
   * Some message/content can be set in message which will be shown in alert body.
   */
  public message: string;
  /**
   * if the value is true alert footer will be visible or else it will be hidden.
   */
  public alertFooter: boolean = true;
  /**
   * shows alert header if the value is true.
   */
  public alertHeader: boolean = true;
  /**
   *  if the value is true ok button align on the left, else on the right
   */
  public okButtonLeft: boolean = true;

  /**
   *  if the value is true ok button is disabled
   */
  @Input()
  public okDisabled: boolean = false;

  /**
   *  If the value is true, a checkbox option will be appeared on the right side of footer
   */
  @Input()
  public choice: boolean = false;

  /**
   * if the value is true then on ok clicked, modal will stay open.
   */
  public stayOpen: boolean = false;

  /**
   *  Value will be emitted if @choice is true
   */
  public select: boolean = true;
  /**
   * Emitted when ok button was clicked
   * or when Ok method is called.
   */
  @Output() public alertOutput: EventEmitter<any> = new EventEmitter();
  /**
   * Emitted when cancel button was clicked
   * or when cancel method is called.
   */
  @Output() public cancelOutput: EventEmitter<any> = new EventEmitter();

  @ViewChild('element') element: ElementRef;
  private subscriptions: any[] = [];

  constructor() {
  }

  ngOnInit() {
    AlertModal.MODAL_COUNTER++;
    this.id = 'modal-' + AlertModal.MODAL_COUNTER;
  }

  ngAfterViewInit() {
    if(this.element && typeof document !== "undefined") {
      this.subscriptions.push(UIkit.util.on(document, 'hide', '#' + this.id, (event: any) => {
        if(event.srcElement.id === this.id) {
          this.cancelOutput.emit(true);
        }
      }));
    }
  }

  ngOnDestroy() {
    if(typeof document !== "undefined") {
      const element = document.getElementById("modal-container");
      for (let i = element.childNodes.length - 1; i >= 0; --i) {
        let child: ChildNode = element.childNodes[i];
        if (child['id'] == this.id) {
          child.remove();
        }
      }
    }
    this.subscriptions.forEach(subscription => {
      if(subscription instanceof Function) {
        subscription();
      }
    });
  }

  /**
   * Opens an alert window creating backdrop.
   */
  open() {
    if(typeof UIkit !== "undefined") {
      UIkit.modal(this.element.nativeElement).show();
      if(this.overflowBody) {
        this.bodyElement.nativeElement.scrollTo(0, 0);
      }
    }
  }

  /**
   *  ok method closes the modal and emits modalOutput.
   */
  ok() {
    if (!this.stayOpen && typeof UIkit !== "undefined") {
      UIkit.modal(this.element.nativeElement).hide();
    }
    if (!this.choice) {
      this.alertOutput.emit(true);
    } else {
      this.alertOutput.emit({
        value: true,
        choice: this.select
      });
    }
  }

  /**
   *  cancel method closes the modal.
   */
  cancel() {
    if(typeof UIkit !== "undefined") {
      UIkit.modal(this.element.nativeElement).hide();
    }
  }

  previous() {
    this.cancelOutput.emit();
  }

	get bodyHeight() {
		return (this.bodyElement) ? this.bodyElement.nativeElement.offsetHeight : 0;
	}
}
