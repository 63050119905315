import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {ConfigurationService} from '../utils/configuration/configuration.service';
import {ConnectHelper} from '../connect/connectHelper';
import {properties} from "../../../environments/environment";
import {HelperFunctions} from "../utils/HelperFunctions.class";

@Injectable({
  providedIn: 'root'
})
export class IsRouteEnabled  {
  
  constructor(private router: Router,
              private config: ConfigurationService) {
  }
  
  check(data: Data, path: string): Observable<boolean> | boolean {
    const customRedirect = data['redirect'];
    const redirect = customRedirect ? customRedirect : properties.errorLink;
    let community = ConnectHelper.getCommunityFromDomain(properties.domain);
    if (!community && data['community']) { // for openaire or connect
      community = data['community'];
    }
    if (!community) {
      community = properties.adminToolsCommunity;
    }
    return this.config.isPageEnabled(properties, community, '/' + path).pipe(take(1), tap((enabled) => {
      if (!enabled) {
        HelperFunctions.navigateToError(this.router, {"page": path}, true, redirect);
      }
    }));
    
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(route.data, state.url);
  }
  
}
