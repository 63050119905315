import {Directive} from "@angular/core";
import {BaseComponent} from "../../sharedComponents/base/base.component";
import {IndicatorUtils, StakeholderUtils} from "./indicator-utils";
import {ConnectHelper} from "../../connect/connectHelper";
import {Indicator, IndicatorSize, Stakeholder} from "../../monitor/entities/stakeholder";
import {HelperFunctions} from "../../utils/HelperFunctions.class";
import {ConfigurationService} from "../../utils/configuration/configuration.service";
import {StatsProfilesService} from "./services/stats-profiles.service";

@Directive()
export abstract class StakeholderBaseComponent extends BaseComponent {
  stakeholderUtils: StakeholderUtils = new StakeholderUtils();
  statsProfiles: string[] = [];

  protected statsProfileService: StatsProfilesService;

  get entities() {
    return this.stakeholderUtils.entities;
  }

  get showVisibility() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.visibilities);
  }

  get showType() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.types);
  }

  get showFunderType() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.funderTypes);
  }

  getFunderTypeLabel(value: any) {
    return this.stakeholderUtils.getLabel(this.stakeholderUtils.funderTypes, value);
  }

  get showLocale() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.locales);
  }

  canEditVisibility(isEditable: boolean) {
    return this.showVisibility && isEditable;
  }

  protected navigateToError() {
    HelperFunctions.navigateToError(this._router, {"page": this._router.url});
  }

  setProperties(id: string, type = null, configurationService: ConfigurationService) {
    this.properties.adminToolsCommunity = id;
    if (type) {
      this.properties.adminToolsPortalType = type;
    } else {
      ConnectHelper.setPortalTypeFromPid(id);
    }
    configurationService.initPortal(this.properties, this.properties.adminToolsCommunity);
  }

  setStatsProfiles(extra: string[] = []) {
    if (this.statsProfileService) {
      this.subscriptions.push(this.statsProfileService.getStatsProfiles().subscribe(statsProfiles => {
        this.statsProfiles = extra.concat(statsProfiles);
      }, error => {
        this.statsProfiles = [];
      }));
    }
  }
}

@Directive()
export abstract class IndicatorStakeholderBaseComponent extends StakeholderBaseComponent {

  indicatorUtils: IndicatorUtils = new IndicatorUtils();
  indicator: Indicator;

  public getNumberClassBySize(size: IndicatorSize): string {
    if (size === 'small') {
      return 'uk-width-medium';
    } else if (size === 'medium') {
      return 'uk-width-max-content';
    } else {
      return 'uk-width-max-content';
    }
  }

  public getChartClassBySize(size: IndicatorSize): string {
    if (size === 'small') {
      return 'uk-width-1-3@xl uk-width-1-2@m uk-width-1-1';
    } else if (size === 'medium') {
      return 'uk-width-1-2@l uk-width-1-1';
    } else {
      return 'uk-width-1-1';
    }
  }

  public getActivePathIndex(indicator: Indicator = this.indicator): number {
    return indicator?.activePath ? indicator.activePath : 0;
  }

  public getActiveIndicatorPath(indicator: Indicator = this.indicator) {
    if(indicator?.indicatorPaths.length >  this.getActivePathIndex(indicator)) {
      return indicator.indicatorPaths[this.getActivePathIndex(indicator)];
    }
    return null;
  }
}
