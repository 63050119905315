import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconsModule} from '../../utils/icons/icons.module';
import {HelpPopUpComponent} from './help-pop-up.component';
import {AlertModalModule} from '../../utils/modal/alertModal.module';


@NgModule({
  imports: [CommonModule, FormsModule, IconsModule, AlertModalModule],
  declarations: [
    HelpPopUpComponent
  ],
  providers:[],
  exports: [HelpPopUpComponent]
})
export class HelpPopUpModule {
}
