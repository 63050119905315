declare var UIkit;

export type Status = 'danger' | 'success' | 'warning';
export type Position = 'bottom-right' | 'bottom-left' | 'bottom-center';

export class NotificationHandler {
  private static DEFAULT_TIMEOUT: number = 6000;
  private static DEFAULT_STATUS: Status = 'success';
  private static DEFAULT_POSITION: Position = 'bottom-right';
  
  public static rise(message: string, status: Status = this.DEFAULT_STATUS, position: Position = this.DEFAULT_POSITION) {
    if(typeof document !== 'undefined') {
      UIkit.notification(message, {
        status: status,
        timeout: this.DEFAULT_TIMEOUT,
        pos: position
      });
    }
  }
}
