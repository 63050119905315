import {EnvProperties} from "../env-properties";

export let common: EnvProperties = {

  bipFrameAPIURL: "https://bip.imsi.athenarc.gr/api/impact-chart?id=",
  useNewStatistisTool: true,
  openCitationsAPIURL: "https://services.openaire.eu/opencitations/getCitations?id=",
  searchCrossrefAPIURL: "https://api.crossref.org/works",
  searchDataciteAPIURL: "https://api.datacite.org/dois",
  searchOrcidURL: "https://pub.orcid.org/v2.1/",
  orcidURL: "https://orcid.org/",
  orcidAPIURL: "https://services.openaire.eu/uoa-orcid-service/",
  orcidTokenURL: "https://orcid.org/oauth/authorize?",
  orcidClientId: "APP-IN0O56SBVVTB7NN4",
  doiURL: "https://doi.org/",
  pmcURL: "http://europepmc.org/articles/",
  pmidURL: "https://www.ncbi.nlm.nih.gov/pubmed/",
  handleURL: "http://hdl.handle.net/",
  erasmusURL: "https://erasmus-plus.ec.europa.eu/it/projects/search/details/",
  cordisURL: "http://cordis.europa.eu/projects/",
  openDoarURL: "http://v2.sherpa.ac.uk/id/repository/",
  r3DataURL: "http://service.re3data.org/repository/",
  swhURL: "https://archive.softwareheritage.org/",
  rorURL: "https://ror.org/",
  isniURL: "https://isni.org/isni/",
  wikiDataURL: "https://www.wikidata.org/wiki/",
  fundRefURL: "https://data.crossref.org/fundingdata/funder/",
  rridURL: "https://scicrunch.org/resolver/",
  arxivURL: "https://arxiv.org/abs/",
  fairSharingURL: "https://fairsharing.org/",
  openScienceCloudURL: "https://open-science-cloud.ec.europa.eu/resources/services/",
  sherpaURL: "https://openpolicyfinder.jisc.ac.uk/search?type=allSearch&allSearchType=&term=",
  sherpaURLSuffix: "&page=1&per_page=10",
  zenodo: "https://zenodo.org/",
  helpdesk: "https://www.openaire.eu/support/helpdesk",
  helpdeskEmail: "helpdesk@openaire.eu",
  piwikBaseUrl: "https://analytics.openaire.eu/piwik.php?idsite=",
  cookieDomain: ".openaire.eu",
  feedbackmail: "feedback@openaire.eu",
  csvLimit: 2000,
  pagingLimit: 20,
  resultsPerPage: 10,
  baseLink : "",
  myClaimsLink: '/myclaims',
  searchLinkToResult: "/search/result?id=",
  searchLinkToPublication: "/search/publication?articleId=",
  searchLinkToProject: "/search/project?projectId=",
  searchLinkToDataProvider: "/search/dataprovider?datasourceId=",
  searchLinkToService: "/search/service?serviceId=",
  searchLinkToDataset: "/search/dataset?datasetId=",
  searchLinkToSoftwareLanding: "/search/software?softwareId=",
  searchLinkToOrp: "/search/other?orpId=",
  searchLinkToOrganization: "/search/organization?organizationId=",
  searchLinkToAll: "/search/find/",
  searchLinkToPublications: "/search/find/publications",
  searchLinkToDataProviders: "/search/find/dataproviders",
  searchLinkToServices: "/search/find/services",
  searchLinkToProjects: "/search/find/projects",
  searchLinkToDatasets: "/search/find/datasets",
  searchLinkToSoftware: "/search/find/software",
  searchLinkToOrps: "/search/find/other",
  searchLinkToOrganizations: "/search/find/organizations",
  searchLinkToCompatibleDataProviders: "/search/content-providers",
  searchLinkToEntityRegistriesDataProviders: "/search/entity-registries",
  searchLinkToJournals: "/search/journals",
  searchLinkToResults: "/search/find/research-outcomes",
  searchLinkToAdvancedPublications: "/search/advanced/publications",
  searchLinkToAdvancedProjects: "/search/advanced/projects",
  searchLinkToAdvancedDatasets: "/search/advanced/datasets",
  searchLinkToAdvancedSoftware: "/search/advanced/software",
  searchLinkToAdvancedOrps: "/search/advanced/other",
  searchLinkToAdvancedDataProviders: "/search/advanced/dataproviders",
  searchLinkToAdvancedServices: "/search/advanced/services",
  searchLinkToAdvancedOrganizations: "/search/advanced/organizations",
  searchLinkToAdvancedResults: "/search/advanced/research-outcomes",
  errorLink: '/error',
  showLastIndexInformationLink: true,
  lastIndexUpdate: "2020-06-15",
  depositLearnHowPage: "/participate/deposit/learn-how",
  depositSearchPage: "/participate/deposit/search",
  altMetricsAPIURL: "https://api.altmetric.com/v1/doi/",
  reCaptchaSiteKey: "6LezhVIUAAAAAOb4nHDd87sckLhMXFDcHuKyS76P",
  admins: ['feedback@openaire.eu'],
  b2noteAPIURL: 'https://b2note.eudat.eu/',
  myOrcidLinksPage: "/my-orcid-links",
  fundersApi: "https://services.openaire.eu/openaire/funders",

  footerGrantText: "OpenAIRE has received funding from <a href='https://www.openaire.eu/projects' target='_blank'>a series of EU funded projects</a>.",

  //connect
  enermapsURL: "https://lab.idiap.ch/enermaps",
  zenodoCommunities: 'https://zenodo.org/api/communities',
  shareInZenodoPage: '/participate/deposit/zenodo',
  afterLoginRedirectLink: '/myCommunities',
  searchLinkToCommunities: '/',
  openOrgsUrl:"https://beta.orgs.openaire.eu",

  // monitor
  searchLinkToStakeholders: "/browse"
}

export let commonDev: EnvProperties = {
  environment: "development",
  pdfStatisticsAPIURL: "https://beta.services.openaire.eu/pdf-stats",
  statisticsAPIURL: "https://beta.services.openaire.eu/stats-api/",
  statisticsFrameAPIURL: "https://beta.openaire.eu/stats/",
  statisticsFrameNewAPIURL: "https://beta.services.openaire.eu/stats-tool/",
  claimsAPIURL: "http://dl170.madgik.di.uoa.gr:19780/uoa-claims-service/claimsService/",
  searchAPIURLLAst: "https://beta.services.openaire.eu/search/v2/api/",
  searchResourcesAPIURL: "https://beta.services.openaire.eu/search/v2/api/resources",
  openCitationsAPIURL: "https://services.openaire.eu/opencitations/getCitations?id=",
  csvAPIURL: "https://beta.services.openaire.eu/search/v2/api/reports",
  orcidAPIURL: "http://dl170.madgik.di.uoa.gr:19480/uoa-orcid-service/",
  orcidTokenURL: "https://sandbox.orcid.org/oauth/authorize?",
  orcidClientId: "APP-A5M3KTX6NCN67L91",
  utilsService: "http://duffy.di.uoa.gr:8000",
  vocabulariesAPI: "https://services.openaire.eu/provision/mvc/vocabularies/",
  loginServiceURL: "http://dl170.madgik.di.uoa.gr:19080/login-service/",
  cookieDomain: ".di.uoa.gr",
  feedbackmail: "kostis30fylloy@gmail.com",
  cacheUrl: "http://dl170.madgik.di.uoa.gr:3000/get?url=",
  monitorServiceAPIURL: "http://dl170.madgik.di.uoa.gr:19380/uoa-monitor-service/",
  adminToolsAPIURL: "http://dl170.madgik.di.uoa.gr:19280/uoa-admin-tools/",
  datasourcesAPI: "https://beta.services.openaire.eu/openaire/ds/api/",
  contextsAPI: "https://dev-openaire.d4science.org/openaire/context",
  communityAPI: "https://dev-openaire.d4science.org/openaire/community/",
  lastIndexInformationLink: "https://www.openaire.eu/aggregation-and-content-provision-workflows",
  widgetLink: "http://scoobydoo.di.uoa.gr/joomla/index.php?option=com_openaire&view=widget&format=raw&projectId=",
  claimsInformationLink: "https://www.openaire.eu/linking-beta",
  indexInfoAPI: "https://beta.services.openaire.eu/openaire/info/",
  admins: ['kostis30fylloy@gmail.com', 'alexandros.martzios@athenarc.gr', 'kgalouni@di.uoa.gr'],
  adminPortalURL: "https://beta.admin.connect.openaire.eu",
  //connect
  communitiesAPI: 'https://dev-openaire.d4science.org/openaire/community/communities',
  registryUrl: 'http://dl170.madgik.di.uoa.gr:19180/uoa-user-management/api/registry/',
  reCaptchaSiteKey: "6LcVtFIUAAAAAB2ac6xYivHxYXKoUvYRPi-6_rLu",
  //admin
  feedbackmailForMissingEntities: 'feedback@openaire.eu',
  connectPortalUrl: 'http://scoobydoo.di.uoa.gr:4200',
  // eosc urls
  eoscMarketplaceURL: "https://search.marketplace.sandbox.eosc-beyond.eu"
}

export let commonTest: EnvProperties = {
  environment: "test",
  pdfStatisticsAPIURL: "https://services.openaire.eu/pdf-stats",
  statisticsAPIURL: "https://beta.services.openaire.eu/stats-api/",
  statisticsFrameAPIURL: "https://www.openaire.eu/stats/",
  statisticsFrameNewAPIURL: "https://services.openaire.eu/stats-tool/",
  claimsAPIURL: "https://services.openaire.eu/claims-new/rest/claimsService/",
  searchAPIURLLAst: "https://services.openaire.eu/shadowSearch/v2/api/",
  searchResourcesAPIURL: "https://services.openaire.eu/shadowSearch/v2/api/resources",
  csvAPIURL: "https://services.openaire.eu/search/v2/api/reports",
  utilsService: "https://explore.openaire.eu/utils-service",
  vocabulariesAPI: "https://services.openaire.eu/provision/mvc/vocabularies/",
  loginServiceURL: " https://services.openaire.eu/login-service/",
  cacheUrl: "https://explore.openaire.eu/cache/get?url=",
  datasourcesAPI: "https://services.openaire.eu/openaire/ds/api/",
  monitorServiceAPIURL: "https://services.openaire.eu/uoa-monitor-service/",
  adminToolsAPIURL: "https://services.openaire.eu/uoa-admin-tools/",
  contextsAPI: "https://services.openaire.eu/openaire/context",
  communityAPI: "https://services.openaire.eu/openaire/community/",
  lastIndexInformationLink: "https://www.openaire.eu/aggregation-and-content-provision-workflows",
  widgetLink: "https://www.openaire.eu/index.php?option=com_openaire&view=widget&format=raw&projectId=",
  claimsInformationLink: "https://www.openaire.eu/linking",
  indexInfoAPI: "https://services.openaire.eu/openaire/info/",
  adminPortalURL: "https://admin.connect.openaire.eu",
  baseOpenaireLink: 'https://explore.openaire.eu',

  // eosc urls
  eoscMarketplaceURL: "https://search.marketplace.sandbox.eosc-beyond.eu"
}

export let commonBeta: EnvProperties = {
  environment: "beta",
  pdfStatisticsAPIURL: "https://beta.services.openaire.eu/pdf-stats",
  statisticsAPIURL: "https://beta.services.openaire.eu/stats-api/",
  statisticsFrameAPIURL: "https://beta.openaire.eu/stats/",
  statisticsFrameNewAPIURL: "https://beta.services.openaire.eu/stats-tool/",
  claimsAPIURL: "https://beta.services.openaire.eu/claims/claimsService/",
  searchAPIURLLAst: "https://beta.services.openaire.eu/search/v2/api/",
  searchResourcesAPIURL: "https://beta.services.openaire.eu/search/v2/api/resources",
  csvAPIURL: "https://beta.services.openaire.eu/search/v2/api/reports",
  utilsService: "https://beta.explore.openaire.eu/utils-service",
  vocabulariesAPI: "https://beta.services.openaire.eu/provision/mvc/vocabularies/",
  loginServiceURL: "https://beta.services.openaire.eu/login-service/",
  cacheUrl: "https://beta.explore.openaire.eu/cache/get?url=",
  datasourcesAPI: "https://beta.services.openaire.eu/openaire/ds/api/",
  monitorServiceAPIURL: "https://beta.services.openaire.eu/uoa-monitor-service/",
  adminToolsAPIURL: "https://beta.services.openaire.eu/uoa-admin-tools/",
  contextsAPI: "https://beta.services.openaire.eu/openaire/context",
  communityAPI: "https://beta.services.openaire.eu/openaire/community/",
  lastIndexInformationLink: "https://beta.openaire.eu/aggregation-and-content-provision-workflows",
  widgetLink: "https://beta.openaire.eu/index.php?option=com_openaire&view=widget&format=raw&projectId=",
  claimsInformationLink: "https://beta.openaire.eu/linking",
  indexInfoAPI: "https://beta.services.openaire.eu/openaire/info/",

  adminPortalURL: "https://beta.admin.connect.openaire.eu",
  baseOpenaireLink: 'https://beta.explore.openaire.eu',

  //connect
  communitiesAPI: "https://beta.services.openaire.eu/openaire/community/communities",
  registryUrl: 'https://beta.services.openaire.eu/uoa-user-management/api/registry/',
  //admin
  feedbackmailForMissingEntities: 'feedback@openaire.eu',
  deleteCacheUrl: 'https://demo.openaire.eu/cache/clear',
  deleteBrowserCacheUrl: 'https://beta.services.openaire.eu/uoa-admin-tools/cache',
  connectPortalUrl: 'https://beta.connect.openaire.eu',

  // eosc urls
  eoscMarketplaceURL: "https://search.marketplace.sandbox.eosc-beyond.eu/"
}

export let commonProd: EnvProperties = {
  environment: "production",
  pdfStatisticsAPIURL: "https://services.openaire.eu/pdf-stats",
  statisticsAPIURL: "https://services.openaire.eu/stats-api/",
  statisticsFrameAPIURL: "https://www.openaire.eu/stats/",
  statisticsFrameNewAPIURL: "https://services.openaire.eu/stats-tool/",
  claimsAPIURL: "https://services.openaire.eu/claims-new/rest/claimsService/",
  searchAPIURLLAst: "https://services.openaire.eu/search/v2/api/",
  searchResourcesAPIURL: "https://services.openaire.eu/search/v2/api/resources",
  csvAPIURL: "https://services.openaire.eu/search/v2/api/reports",
  utilsService: "https://explore.openaire.eu/utils-service",
  vocabulariesAPI: "https://services.openaire.eu/provision/mvc/vocabularies/",
  loginServiceURL: "https://services.openaire.eu/login-service/",
  cacheUrl: "https://explore.openaire.eu/cache/get?url=",
  datasourcesAPI: "https://services.openaire.eu/openaire/ds/api/",
  monitorServiceAPIURL: "https://services.openaire.eu/uoa-monitor-service/",
  adminToolsAPIURL: "https://services.openaire.eu/uoa-admin-tools/",
  contextsAPI: "https://services.openaire.eu/openaire/context",
  communityAPI: "https://services.openaire.eu/openaire/community/",
  lastIndexInformationLink: "https://www.openaire.eu/aggregation-and-content-provision-workflows",
  widgetLink: "https://www.openaire.eu/index.php?option=com_openaire&view=widget&format=raw&projectId=",
  claimsInformationLink: "https://www.openaire.eu/linking",
  indexInfoAPI: "https://services.openaire.eu/openaire/info/",
  adminPortalURL: "https://admin.connect.openaire.eu",
  baseOpenaireLink: 'https://explore.openaire.eu',

  //connect
  communitiesAPI: "https://services.openaire.eu/openaire/community/communities",

  //admin
  registryUrl: 'https://services.openaire.eu/uoa-user-management/api/registry/',
  feedbackmailForMissingEntities: 'feedback@openaire.eu',
  deleteCacheUrl: 'https://explore.openaire.eu/cache/clear',
  deleteBrowserCacheUrl: 'https://services.openaire.eu/uoa-admin-tools/cache',
  connectPortalUrl: 'https://connect.openaire.eu',

  //irish
  openOrgsUrl:"https://orgs.openaire.eu",

  // eosc urls
  eoscMarketplaceURL: "https://search.marketplace.eosc-beyond.eu"
}


/*
*

Properties to check

* not in explore

* isDashboard
showContent

enableEoscDataTransfer


developersApiUrl?: string,

useHelpTexts?:boolean;


  searchLinkToStakeholders?: string;

searchLinkToAdvancedServices?: string;


sushiliteURL?: string;

notificationsAPIURL?: string;


egiNotebookLink?: string;

eoscDataTransferAPI?;
  eoscDataTransferLoginUrl?;
  eoscDataTransferDestinations?;
* */

