import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ContactComponent} from './contact.component';
import {ContactRoutingModule} from "./contact-routing.module";
import {EmailService} from "../openaireLibrary/utils/email/email.service";
import {AlertModalModule} from "../openaireLibrary/utils/modal/alertModal.module";
import {IsRouteEnabled} from "../openaireLibrary/error/isRouteEnabled.guard";
import {Schema2jsonldModule} from "../openaireLibrary/sharedComponents/schema2jsonld/schema2jsonld.module";
import {ContactUsModule} from "../openaireLibrary/contact-us/contact-us.module";
import {IconsModule} from "../openaireLibrary/utils/icons/icons.module";
import {FullScreenModalModule} from "../openaireLibrary/utils/modal/full-screen-modal/full-screen-modal.module";


@NgModule({
  imports: [
    ContactRoutingModule, CommonModule, RouterModule,
    AlertModalModule,
    Schema2jsonldModule, ContactUsModule, IconsModule, FullScreenModalModule
  ],
  declarations: [
    ContactComponent
  ],
  providers: [
    EmailService, IsRouteEnabled
  ],
  exports: [
    ContactComponent
  ]
})

export class ContactModule { }
