import {Email} from "./email";
import {Body} from "./body";
import {properties} from "../../../../environments/environment";
import {Role, User} from "../../login/utils/helper.class";

export class Composer {
  private static noteBodySize = "14px";
  private static noteFontSize = "11px";

  private static subjectPrefix = "[OpenAIRE-Connect] ";
  private static closing = "OpenAIRE team";
  private static openaireAddress = "<a href='https://www.openaire.eu'>www.openaire.eu</a><br>";
  private static signature =  Composer.closing + "<br>" + Composer.openaireAddress;

  private static ifYouAreNotResponsible(mail): string {
      return "If you are not responsible for this community, please "
              + "<a href='mailto:" + mail + "'>contact us</a>. ";
  }

  private static manageNotificationSettings(communityId: string): string {
      return "Click  <a href='" + properties.adminPortalURL + "/" + communityId + "/users/notifications"
              + communityId + "'>here</a> to manage your notification settings. ";
  }

  private static youAreManagerOfTheCommunity(communityId: string, communityName: string): string {
      return "You are receiving this e-mail as manager of the community "
              + "<a href='https://"+ (properties.environment == "production"?'':'beta.') + communityId + ".openaire.eu/'>" + communityName + "</a>. ";
  }

  public static composeEmailForNewManager(communityId: string, communityName: string): Email {
      let email: Email = new Email();

      email.subject = this.subjectPrefix + communityName + ": Welcome new manager";
      email.body = "<div style='font-size:" + this.noteBodySize + "'><p>Welcome to OpenAIRE Connect!</p>"
                    + "<p>You are receiving this e-mail as you were assigned as  " + Role.ROLES['manager'] + " of the  <a href='https://beta."
                    + communityId + ".openaire.eu/'>" + communityName + "</a> gateway. "
                    + "In order to access the administration section of your community you must first login using one of the available options. "
                    + "<br>The administrative rights are associated with the e-mail address that was used to send you this message."
                    + " If you login with an account associated to a different email, please  <a href='mailto:helpdesk@openaire.eu'>contact us</a>"
                    + " or your colleagues, that already have access to the administration tool, to update your e-mail. <br>"
                    + "If you do not know which is the email associated with your OpenAIRE account, you can follow the instructions of <a href='https://youtu.be/Z3ePc4ltJ4M?t=30'>this video (from 00:30 to 1:00).</a><br>"
                    + "Once logged in, you can access the administration tool by clicking the \"Manage\" button that will be available in the top right corner of the gateway."
                    + "</p>"
                    + this.signature
                    + "<p style='font-size:" + this.noteFontSize + "'>" + this.ifYouAreNotResponsible(properties.admins[0]) + "<p>"
                    + "</div>";
      return email;
  }

  public static composeEmailForNewCommunity(contactForm: any, admins: any): Email {
    let email: Email = new Email();

    email.subject = "OpenAIRE - Connect";
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
               + "<span><b>Name</b>: " + contactForm.name + "</span><br>"
               + "<span><b>Surname</b>: " + contactForm.surname + "</span><br>"
               + "<span><b>Email</b>: " + contactForm.email + "</span><br>"
               + "<span><b>Affiliation</b>: " + contactForm.affiliation + "</span><br>"
               + "<span><b>Community Name</b>: " + contactForm.community + "</span>"
               + "<p>" + contactForm.message + "</p>"
               + "</div>";
    email.recipients = admins;
    return email;
  }

  public static composeEmailForMonitor(contactForm: any, admins: string[]): Email {
    let email: Email = new Email();

    email.subject = "OpenAIRE - Monitor";
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
      + "<span><b>Name</b>: " + contactForm.name + "</span><br>"
      + "<span><b>Surname</b>: " + contactForm.surname + "</span><br>"
      + "<span><b>Email</b>: " + contactForm.email + "</span><br>"
      + "<span><b>Job Title</b>: " + contactForm.job + "</span><br>"
      + "<span><b>Organization</b>: " + contactForm.organization + " (" + contactForm.organizationType + " )</span>"
      + "<p>" + contactForm.message + "</p>"
      + "</div>";
    email.recipients = admins;
    return email;
  }

	public static composeEmailForExplore(contactForm: any, admins: any): Email {
		let email: Email = new Email();

		email.subject = "OpenAIRE - Explore [" + properties.environment.toUpperCase() + "]";
		email.body = "<div style='font-size:" + this.noteBodySize + "'>"
			+ "<span><b>Name</b>: " + contactForm.name + "</span><br>"
			+ "<span><b>Surname</b>: " + contactForm.surname + "</span><br>"
			+ "<span><b>Email</b>: " + contactForm.email + "</span><br>"
			+ "<span><b>Affiliation</b>: " + (contactForm.affiliation ? contactForm.affiliation : '-') + "</span><br>"
			+ "<p>" + contactForm.message + "</p>"
			+ "</div>";
    email.recipients = admins;
		return email;
	}

  public static composeEmailforIrishMonitor(contactForm: any, admins: any): Email {
    let email: Email = new Email();

    email.subject = "National OA Monitor Ireland | " + contactForm.subject;
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
      + "<span><b>Name</b>: " + contactForm.name + "</span><br>"
      + "<span><b>Email</b>: " + contactForm.email + "</span><br>"
      + "<p>" + contactForm.message + "</p>"
      + "</div>";
    email.recipients = admins;
    return email;
  }

  public static composeEmailForDevelopPersonalInfo(user: User): Email {
    let email: Email = new Email();
    email.subject = "Welcome to The OpenAIRE API mailing list";
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
        + "<p>Dear " + user.fullname + ",</p>"
        + "<p>As a user of the OpenAIRE APIs, you are a member of the openaire-api mailing list. " +
        " We will use this list to inform you of relevant news and updates. If you wish to unsubscribe, " +
        " please send an email to the list admin at <a href='mailto:gbikas@openaire.eu'>gbikas@openaire.eu</a> " +
        " and <a href='mailto:stefania.amodeo@openaire.eu'>stefania.amodeo@openaire.eu</a>.</p>"
        + "<p>The OpenAIRE Graph team</p>";
    email.recipients = [user.email];
    return email;
  }

  public static composeEmailForDevelop(contactForm: any, admins: any, user: User): Email {
		let email: Email = new Email();
		email.subject = "OpenAIRE - Develop [" + properties.environment.toUpperCase() + "]";
		email.body = "<div style='font-size:" + this.noteBodySize + "'>"
        + "<span><b>Name</b>: " + user.firstname + "</span><br>"
        + "<span><b>Surname</b>: " + user.lastname + "</span><br>"
        + "<span><b>Email</b>: " + user.email + "</span><br>"
        + "<span><b>Subject</b>: " + (contactForm.subject ? contactForm.subject : '-') + "</span><br>"
        + "<p>" + contactForm.message + "</p>"
        + "</div>";
    email.recipients = admins;
		return email;
	}

  public static composeEmailForUsageCounts(contactForm: any, admins: any): Email {
    let email: Email = new Email();

    email.subject = "OpenAIRE - UsageCounts";
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
      + "<span><b>Name</b>: " + contactForm.name + "</span><br>"
      + "<span><b>Email</b>: " + contactForm.email + "</span><br>"
      + "<span><b>Affiliation</b>: " + contactForm.affiliation + "</span><br>"
      + "<span><b>Organization</b>: " + contactForm.organization + "</span>"
      + "<p>" + contactForm.description + "</p>"
      + "</div>";
    email.recipients = admins;
    return email;
  }

  public static composeEmailForGraph(contactForm: any, admins: any): Email {
    let email: Email = new Email();

    email.subject = "OpenAIRE - Research Graph";
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
      + "<span><b>Name</b>: " + contactForm.name + "</span><br>"
      + "<span><b>Email</b>: " + contactForm.email + "</span><br>"
      + "<span><b>Affiliation</b>: " + contactForm.affiliation + "</span><br>"
      + "<span><b>Organization</b>: " + contactForm.organization + "</span>"
      + "<p>" + contactForm.description + "</p>"
      + "</div>";
    email.recipients = admins;
    return email;
  }

  public static composeEmailForFeedback(info: {name: string, url: string, email: string, issues: any[]}, recipients: string[]): Email {
    let email: Email = new Email();
    email.subject = 'Feedback report for ' + info.name;
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
                  + "<p>A user" + ((info.email)?(" with email " + info.email):"") + " has reported the following issue(s) for "
                  + "<a href=\'" + info.url + "\'>" + info.name + "</a></p><ul>";
    info.issues.forEach((issue, index) => {
      email.body += "<br><li><span><b><u>" + issue.field + "</u>: </b></span>" + issue.report + "</li>";
    });
    email.body += "</ul></div>";
    email.recipients = recipients;
    return email;
  }

	public static composeEmailForSdgsSuggestion(info: {name: string, url: string, email: string, subjects: any[]}, recipients: string[], subjectType: "sdg" | "fos"): Email {
    let typeName: string = "";
    if(subjectType == "sdg") {
      typeName = "Sustainable Development Goals (SDGs)";
    } else {
      typeName = "Fields of Science (FoS)";
    }

    let email: Email = new Email();
    email.subject = 'Feedback report for ' + info.name;
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
                  + "<p>A user" + ((info.email)?(" with email " + info.email):"") + " has reported the following "+typeName+" for "
                  + "<a href=\'" + info.url + "\'>" + info.name + "</a></p><ul>";
    if(info.subjects && info.subjects.length > 0) {
      info.subjects.forEach((subject, index) => {
        email.body += "<br><li><span><b>" + subject + "</b></span></li>";
      });
    } else {
      email.body += "<br><b>No "+typeName+" selected</b>";
    }
    email.body += "</ul></div>";
    email.recipients = recipients;
    return email;
  }

  public static composeEmailForUserAfterFeedback(recipients: string[]): Email {
    let email: Email = new Email();
    email.subject = 'Feedback report received';
    email.body = "<div style='font-size:" + this.noteBodySize + "'>"
      + "<p>This is an automated message to let you know that your feedback has been successfully received. Our graph experts will get back to you  as soon as possible.</p><br>"
      + "Thank you for contacting OpenAIRE."
      + "</div>";
    email.recipients = recipients;
    return email;
  }

  public static composeEmailToInformOldManagersForTheNewOnes(communityName: string, communityId: string) : Email {
      let email: Email = new Email();

      email.subject = this.subjectPrefix + communityName + ": Managers list notification";
      email.body = "<div style='font-size:" + this.noteBodySize + "'>"
                    + "<p>There are updates in the managers list for \"" + communityName + "\" community.<br>"
                    + "The list of managers is: ((__managers__))</p>"
                    + this.signature
                    + "<p style='font-size:" + this.noteFontSize + "'>"
                    + this.youAreManagerOfTheCommunity(communityId, communityName)
                    + this.ifYouAreNotResponsible(properties.admins[0])
                    + "<br>"
                    + this.manageNotificationSettings(communityId)
                    + "</p>"
                    + "</div>";
      return email;
  }

  public static composeEmailToInformManagers(communityName: string, communityId: string, subscriberName: string): Email {
    let email: Email = new Email();

    email.subject = this.subjectPrefix + communityName + ": New Member notification";
    email.body = "<div style='font-size" + this.noteBodySize + "'>"
                 + "<p>There is a new member ("+subscriberName+") for \"" + communityName + "\" community. Click  "
                 + "<a href='" + properties.adminPortalURL + "/" + communityId + "/users/member"
                 + "'>here</a> to manage the members list. </p>"
                 + this.signature
                 + "<p style='font-size:" + this.noteFontSize + "'>"
                 + this.youAreManagerOfTheCommunity(communityId, communityName)
                 + "<br>"
                 + this.manageNotificationSettings(communityId)
                 + "</p>"
                 + "</div>";
    return email;
  }

  public static composeEmailToReportCachingProcess(report: any) {
    let email: Email = new Email();
    email.recipients = [report.creator];
    email.subject = report.name + '\'s caching process has been finished';
    email.body = "<div style='font-size" + this.noteBodySize + "'>"
      + "<p>"
      + "<div>Success: " + report.success + "/" + report.total + "</div>"
      + "<div>Errors: " + report.errors.map(error => error.url + ' - ' + error.status).join('<br>') + "</div>"
      + "</p>"
      + "</div>";
    return email;
  }

  public static formatEmailBodyForInvitation(body: Body): string {
    let fromMessageAndName = "";

    if (body.fromName != "") {
      fromMessageAndName = "<span>" + body.fromMessage + body.fromName + "</span>";
    }

    return "<div style='font-size:" + this.noteBodySize + "'>"
        + body.paragraphs
        + "<p>" + body.signature + fromMessageAndName + "<br>"
        + this.openaireAddress
        + "</p>"
        + "</div>";
  }

  // TODO remove this after adding this on admin
  public static initializeInvitationsBody(communityId: string, communityTitle: string, fullname: string): Body {
    let defaultMainBody = '<p>You are invited to join <a href="https://' + (properties.environment == "production"?'':'beta.')  + communityId + '.openaire.eu/">'
      + communityTitle + '</a> dashboard.<br>';
    if(communityId !== 'covid-19') {
       defaultMainBody += 'The purpose of this dashboard  is to gather, link &amp; monitor the research results related to your community.</p>'
        + '<p>The community dashboard is part of the <a href="https://connect.openaire.eu/">OpenAIRE-Connect</a> '
        + 'project.'
        + '</p>';
    } else {
      defaultMainBody += 'This is a dashboard that gathers, links &amp; monitors research results related COVID-19 (multi-disciplinary) from authoritative OA sources around the world. ' +
        'We have a community of experts moderating and curating the content.</p> ' +
        '<p>What you can do:</p>' +
        '<ul>' +
        '<li>navigate in a linked open data space, a contextual discovery</li>' +
        '<li>automatically deposit in Zenodo designated communities</li>' +
        '<li>link research results among themselves and to projects</li>' +
        '<li>join the team of experts to curate/moderate the traffic, links to the data</li>' +
        '</ul> ' +
        '<p>The community dashboard is part of the<a href="https://connect.openaire.eu/"> OpenAIRE-Connect</a> project.</p>'
    }

    return {fromMessage: ", on behalf of ", fromName: fullname, paragraphs: defaultMainBody, signature: this.closing, note: ""};
  }

  public static initializeInvitationsEmail(communityTitle: string) {
    let email: Email = new Email();

    return {body: "", subject: this.subjectPrefix + communityTitle, recipients: []};
  }

  public static composeEmailForMonitorDashboard(name: string, recipient: string, role: "manager" | "member") {
    let email: Email = new Email();
    email.subject = 'OpenAIRE Monitor Dashboard | ' + name;
    email.body =  this.composeMessageForMonitorDashboard(name, role);
    email.recipient = recipient;
    return email;
  }

  public static composeMessageForMonitorDashboard(name: string, role: "manager" | "member", user: string = null, invitation: any = null) {
		let pdppLink = 'http://catalogue.openaire.eu/files/Personal%20Data%20Protection%20Policy%20for%20OpenAIRE%20Services%20April%202022.pdf';
    let message = '<p>Dear ((__user__)),</p>' +
      '<p>You have been invited to be a ' + role +' of the OpenAIRE Monitor Dashboard for the ' + name + '.</p>' +
      '<p>Click <a href="((__link__))" target="_blank">this URL</a> and use the verification code below to accept the invitation.</p>' +
      '<p>The verification code is <b>((__code__))</b>.</p>' +
			'<p>By logging in and using the service you accept and agree to the <a href="' + pdppLink + '" target="_blank">OpenAIRE personal data protection policy</a>.</p>' +
      (role === "manager"?
        '<p>As a ' + Role.ROLES[role] + ' of the OpenAIRE Monitor Dashboard, you will have access to the administration part of the dashboard, ' +
        'where you will be able to customize and manage the profile of the ' + name + '.</p>':
        '<p>As a ' + Role.ROLES[role] + ' of the OpenAIRE Monitor Dashboard, you will have access to the restricted access areas of the profile for the ' + name + '.') +
      '<p>Please contact us at <a href="mailto:' + properties.helpdeskEmail+'">' +  properties.helpdeskEmail +
      '</a> if you have any questions or concerns.</p>' +
      '<p>Kind Regards<br>The OpenAIRE Team</p>' +
      '<p><a href="' + properties.domain + '" target="_blank">OpenAIRE Monitor</a></p>';
    if(user) {
      message = message.replace('((__user__))', user);
    }
    if(invitation) {
      message = message.replace('((__link__))', invitation.link).replace('((__code__))', invitation.code);
    }
    return message;
  }

  public static composeMessageForIrishDashboard(name: string, recipient: string, role: "manager" | "member") {
    let email: Email = new Email();
    email.subject = 'National Open Access Monitor Ireland | ' + name;
    email.recipient = recipient;
    email.body = '<p>Dear user,</p>' +
        '<p>You have been invited to be a ' + Role.ROLES[role] +' of the for the National Open Access Monitor, Ireland dashboard for the ' + name + '.</p>' +
        '<p>Click <a href="((__link__))" target="_blank">this URL</a> and use the verification code below to accept the invitation.</p>' +
        '<p>The verification code is <b>((__code__))</b>.</p>' +
        '<p>At your first sign in you will be asked to accept and consent to the "OpenAIRE Personal Data Protection Policy and Consent Form" to be able to use the service.</p>' +
        (role === "manager"?
            '<p>As a ' + Role.ROLES[role] + ' of the National Open Access Monitor, Ireland, you will have access to the administration part of the dashboard, where you will be able to also invite other users to become ' + Role.ROLES['member'] + 's.</p>':
            '<p>As a ' + Role.ROLES[role] + ' of the National Open Access Monitor, Ireland, you will have access to the sandbox of the profile for the ' + name + '.') +
        '<p>Please contact us at <a href="mailto:' + properties.helpdeskEmail+'">' +  properties.helpdeskEmail +
        '</a> if you have any questions or concerns.</p>' +
        '<p>Kind Regards<br>The OpenAIRE Team</p>' +
        '<p><a href="' + properties.domain + '" target="_blank">National Open Access Monitor, Ireland</a></p>';
    return email;
  }


  public static composeEmailForCommunityDashboard(name: string, role: "manager" | "member", recipient: string) {
    let email: Email = new Email();
    email.subject = 'OpenAIRE Connect | ' + name;
    email.body = this.composeMessageForCommunityDashboard(name, role);
    email.recipient = recipient;
    return email;
  }

  public static composeMessageForCommunityDashboard(name: string, role: "manager" | "member", user: string = null, invitation: any = null) {
    let message = '<p>Dear ((__user__)),</p>' +
      '<p>You have been invited to be a ' + role + ' of the OpenAIRE Research Community Dashboard for the ' + name + '.</p>' +
      '<p>Click <a href="((__link__))" target="_blank">this URL</a> and use the verification code below to accept the invitation.</p>' +
      '<p>The verification code is <b>((__code__))</b>.</p>' +
        (role === 'manager'?
      '<p>As a ' + Role.ROLES[role] + ' of the OpenAIRE Research Community Dashboard, you will have access to the administration part of the dashboard, ' +
      'where you will be able to customize and manage the content of the ' + name + '.</p>':
      '<p>As a ' + Role.ROLES[role] + ' of the OpenAIRE Research Community Dashboard, you will have access to the community dashboard and link research results with projects, communities and other research projects.</p>') +
      '<p>Please contact us at <a href="mailto:' + properties.helpdeskEmail+'">' +  properties.helpdeskEmail +
      '</a> if you have any questions or concerns.</p>' +
      '<p>Kind Regards<br>The OpenAIRE Team</p>' +
      '<p><a href="https://' + (properties.environment == "production"?'':'beta.')  + 'connect.openaire.eu/" target="_blank">OpenAIRE Connect</a></p>';
    if(user) {
      message = message.replace('((__user__))', user);
    }
    if(invitation) {
      message = message.replace('((__link__))', invitation.link).replace('((__code__))', invitation.code);
    }
    return message;
  }
}
