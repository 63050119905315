import {properties} from "../../environments/environment";

interface Links {
  searchLinkToResult;
  searchLinkToPublication;
  searchLinkToProject;
  searchLinkToDataProvider;
  searchLinkToDataset;
  searchLinkToSoftwareLanding;
  searchLinkToOrp;
  searchLinkToOrganization;
  searchLinkToResults,
  searchLinkToPublications,
  searchLinkToDatasets,
  searchLinkToSoftware,
  searchLinkToOrps,
  searchLinkToProjects,
  searchLinkToDataProviders,
  searchLinkToOrganizations,
  searchLinkToAdvancedResults,
  searchLinkToAdvancedPublications,
  searchLinkToAdvancedDatasets
  searchLinkToAdvancedSoftware,
  searchLinkToAdvancedOrps,
  searchLinkToAdvancedProjects,
  searchLinkToAdvancedDataProviders,
  searchLinkToAdvancedOrganizations,
  errorLink
}

export class LinksResolver {
  
  private static default: Links = {
    searchLinkToResult: properties.searchLinkToResult,
    searchLinkToPublication: properties.searchLinkToPublication,
    searchLinkToProject: properties.searchLinkToProject,
    searchLinkToDataProvider: properties.searchLinkToDataProvider,
    searchLinkToDataset: properties.searchLinkToDataset,
    searchLinkToSoftwareLanding: properties.searchLinkToSoftwareLanding,
    searchLinkToOrp: properties.searchLinkToOrp,
    searchLinkToOrganization: properties.searchLinkToOrganization,
    searchLinkToResults: properties.searchLinkToResults,
    searchLinkToPublications: properties.searchLinkToPublications,
    searchLinkToDatasets: properties.searchLinkToDatasets,
    searchLinkToSoftware: properties.searchLinkToSoftware,
    searchLinkToOrps: properties.searchLinkToOrps,
    searchLinkToDataProviders: properties.searchLinkToDataProviders,
    searchLinkToProjects: properties.searchLinkToProjects,
    searchLinkToOrganizations: properties.searchLinkToOrganizations,
    searchLinkToAdvancedResults: properties.searchLinkToAdvancedResults,
    searchLinkToAdvancedPublications: properties.searchLinkToAdvancedPublications,
    searchLinkToAdvancedDatasets: properties.searchLinkToAdvancedDatasets,
    searchLinkToAdvancedSoftware: properties.searchLinkToAdvancedSoftware,
    searchLinkToAdvancedOrps: properties.searchLinkToAdvancedOrps,
    searchLinkToAdvancedProjects: properties.searchLinkToAdvancedProjects,
    searchLinkToAdvancedDataProviders: properties.searchLinkToAdvancedDataProviders,
    searchLinkToAdvancedOrganizations: properties.searchLinkToAdvancedOrganizations,
    errorLink: properties.errorLink
  };

  public static setProperties(alias: string) {
    Object.keys(this.default).forEach(field => {
      properties[field] = "/" + alias + (<string>this.default[field]);
    });
  }

  public static setSearchAndResultLanding(alias: string) {
    properties.searchLinkToResult= "/" + alias + this.default.searchLinkToResult;
    properties.searchLinkToPublication= "/" + alias + this.default.searchLinkToPublication;
    properties.searchLinkToDataset= "/" + alias + this.default.searchLinkToDataset;
    properties.searchLinkToSoftwareLanding= "/" + alias + this.default.searchLinkToSoftwareLanding;
    properties.searchLinkToOrp= "/" + alias + this.default.searchLinkToOrp;
    properties.searchLinkToResults= "/" + alias + this.default.searchLinkToResults;
    properties.searchLinkToPublications= "/" + alias + this.default.searchLinkToPublications;
    properties.searchLinkToDatasets= "/" + alias + this.default.searchLinkToDatasets;
    properties.searchLinkToSoftware= "/" + alias + this.default.searchLinkToSoftware;
    properties.searchLinkToOrps= "/" + alias + this.default.searchLinkToOrps;
    properties.searchLinkToAdvancedResults= "/" + alias + this.default.searchLinkToAdvancedResults;
    properties.searchLinkToAdvancedPublications= "/" + alias + this.default.searchLinkToAdvancedPublications;
    properties.searchLinkToAdvancedDatasets= "/" + alias + this.default.searchLinkToAdvancedDatasets;
    properties.searchLinkToAdvancedSoftware= "/" + alias + this.default.searchLinkToAdvancedSoftware;
    properties.searchLinkToAdvancedOrps= "/" + alias + this.default.searchLinkToAdvancedOrps;

  }

  public static resetProperties() {
    this.setProperties('national/');
  }
}
