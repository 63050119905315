import {APP_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoadingModule} from "./openaireLibrary/utils/loading/loading.module";
import {NavigationBarModule} from "./openaireLibrary/sharedComponents/navigationBar.module";
import {BottomModule} from "./openaireLibrary/sharedComponents/bottom.module";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {HttpInterceptorService} from "./openaireLibrary/http-interceptor.service";
import {ErrorInterceptorService} from "./openaireLibrary/error-interceptor.service";
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from "./openaireLibrary/timeout-interceptor.service";
import {SharedModule} from "./openaireLibrary/shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ErrorModule} from "./openaireLibrary/error/error.module";
import {CookieLawModule} from "./openaireLibrary/sharedComponents/cookie-law/cookie-law.module";
import {SearchResearchResultsServiceModule} from "./openaireLibrary/services/searchResearchResultsService.module";
import {SearchOrcidService} from "./openaireLibrary/claims/claim-utils/service/searchOrcid.service";
import {SearchOrcidServiceModule} from "./openaireLibrary/claims/claim-utils/service/searchOrcidService.module";
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './openaireLibrary/shared/custom-route-reuse-strategy';
import {HelperModule} from "./openaireLibrary/utils/helper/helper.module";
import {ContactModule} from "./contact/contact.module";
import {HelpPopUpModule} from "./openaireLibrary/monitor/help-pop-up/help-pop-up.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ErrorModule,
    LoadingModule,
    NavigationBarModule,
    BottomModule,
    AppRoutingModule,
    CookieLawModule,
    SearchResearchResultsServiceModule,
    SearchOrcidServiceModule,
    HelpPopUpModule,
    HelperModule, ContactModule], providers: [
    SearchOrcidService,
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    {provide: APP_ID, useValue: 'irish-monitor'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    [{provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true}],
    [{provide: DEFAULT_TIMEOUT, useValue: 30000}],
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
